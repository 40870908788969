<template>
    <div class="box">
        <div class="tabs">
            <div class="tab" v-for="tab in tabs" :key="tab.type" :class="type == tab.type ? 'active' : ''"
                @click="onTabsChange(tab.type)">{{ tab.name }}</div>
        </div>
        <div class="privilege">
            <template v-if="type == 1">
                <div class="title">VIP会员权益</div>
                <div class="ul">
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-jf.png" />
                        </div>
                        <div class="fr">
                            <div class="name">活动多赚</div>
                            <div class="desc">扫码核销享VIP会员价，赚更多</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-js.png" />
                        </div>
                        <div class="fr">
                            <div class="name">优先审核</div>
                            <div class="desc">享核销订单优先审核特权</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-v.png" />
                        </div>
                        <div class="fr">
                            <div class="name">会员标识</div>
                            <div class="desc">尊享个人VIP会员标识</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-kf.png" />
                        </div>
                        <div class="fr">
                            <div class="name">专属客服</div>
                            <div class="desc">享会员专属客服特权</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="title">合伙人权益</div>
                <div class="ul">
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-tg.png" />
                        </div>
                        <div class="fr">
                            <div class="name">推广收益</div>
                            <div class="desc">
                                <!-- <div>分享推广用户使用享佣金</div> -->
                                <div>用户升级VIP奖励1200积分；</div>
                                <div>用户升级合伙人奖励2万积分；</div>
                                <div>用户每次扫码核销奖励1-200积分；</div>
                            </div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-jf.png" />
                        </div>
                        <div class="fr">
                            <div class="name">全价扫码</div>
                            <div class="desc">核销享全价扫码</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-js.png" />
                        </div>
                        <div class="fr">
                            <div class="name">优先审核</div>
                            <div class="desc">享核销订单优先审核特权</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-v.png" />
                        </div>
                        <div class="fr">
                            <div class="name">星标标识</div>
                            <div class="desc">尊享合伙人星标标识</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="fl">
                            <img class="icon" src="@/assets/icon-vip-kf.png" />
                        </div>
                        <div class="fr">
                            <div class="name">专属客服</div>
                            <div class="desc">享合伙人专属客服特权</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="rule">
            <div class="title">详细规则</div>
            <div class="ul">
                <div class="li">· 软件特价费用，不支付任何退款，请确认再付款</div>
                <div class="li">· 付款：确认购买后可选择微信付款</div>
            </div>
        </div>
        <div class="footer">
            <div class="pay-button">
                <!-- <div class="tj">即将恢复原价</div> -->
                <div class="fl">
                    <span>限时特价</span>
                    <span class="y">￥</span>
                    <span class="money">{{ amount }}</span>
                    <span class="original">原价￥{{ original }}</span>
                </div>
                <div class="fr" @click="onPay">立即支付</div>
            </div>
        </div>
    </div>
</template>
<script>
const jweixin = require('jweixin-module');
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
    data() {
        return {
            type: 1,
            tabs: [
                {
                    name: "开通VIP会员",
                    type: 1,
                    code: 101,
                    amount: 20,
                    original: 128
                },
                {
                    name: "开通合伙人",
                    type: 2,
                    code: 102,
                    amount: 400,
                    original: 899
                },
            ],
            appid: process.env.VUE_APP_APPID,
            openid: "",
            code: 101,
            amount: 20,
            original: 128,
            isWeixinConfig: false
        }
    },
    created() {
        const code = this.$route.query.code;
        if (code) {
            this.wxConfig();
            this.getOpenId(code);
        } else {
            this.wxLogin();
        }
    },
    methods: {
        onTabsChange(type) {
            let tab = this.tabs.find(item => parseInt(item.type) == parseInt(type));
            this.code = tab.code;
            this.amount = tab.amount;
            this.original = tab.original;
            this.type = type;
        },
        wxLogin() {
            let scope = "snsapi_base" //snsapi_userinfo,snsapi_base
            let redirect = encodeURIComponent("https://h5.yanhe.szwwhl.com/pay");
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;
            window.location.href = url;
        },
        getOpenId(code) {
            this.request.post("/wx/mp/getOpenId", {
                code: code
            }).then(res => {
                if (res.code == 1) {
                    this.openid = res.data.openId;
                } else {
                    Toast({ message: res.msg, position: 'cente' });
                }
            }).catch(err => {
                Toast({ message: '获取openid失败，请稍后重试', position: 'cente' });
            })
        },
        wxConfig() {
            this.request.post("/wx/mp/signature", {
                url: window.location.href,
            }).then(res => {
                let that = this;
                if (res.code == 1) {
                    jweixin.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.data.appId, // 必填，公众号的唯一标识
                        timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
                        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: ["onMenuShareTimeline",
                            "onMenuShareAppMessage",
                            "onMenuShareQQ",
                            "onMenuShareWeibo",
                            "onMenuShareQZone",
                            "chooseWXPay",
                            "updateAppMessageShareData",
                            "updateTimelineShareData",
                            "openAddress",
                            "scanQRCode"] // 必填，需要使用的JS接口列表
                    });
                    jweixin.ready(function () {
                        that.isWeixinConfig = true;
                    });
                    jweixin.error(function (res) {
                        Toast({ message: '初始化微信config失败', position: 'cente' });
                    });
                }
            }).catch(err => {
                Toast({ message: '获取微信config数据失败', position: 'cente' });
            })
        },
        onPay() {
            let toast = Toast.loading({ message: '加载中...', duration: 0 });
            this.request.post("/pay/app/order", {
                code: this.code,
                payType: 3,
                appid: this.appid,
                openId: this.openid,
            }).then(res => {
                if (res.code == 1) {
                    jweixin.chooseWXPay({
                        timestamp: parseInt(res.data.timestamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                        nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
                        package: res.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: res.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                        paySign: res.data.paySign, // 支付签名
                        success: function (res) {
                            // 支付成功后的回调函数
                            Dialog.alert({
                                title: '支付成功',
                                message: '恭喜支付成功，已为您开通服务',
                                confirmButtonColor: "#15c176"
                            }).then(() => {
                                // on close
                            });
                        },
                        fail: function (err) {

                        }
                    });
                } else {
                    Toast({ message: '创建支付订单失败', position: 'cente' });
                }
            }).catch(err => {
                Toast({ message: '创建支付订单失败', position: 'cente' });
            }).finally(() => {
                toast.clear()
            })
        }
    }
}
</script>
<style scoped>
.box {
    padding-bottom: 70px;
}

.tabs {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    line-height: 60px;
    border-bottom: #f0f0f0 solid 1px;
    background: #ffffff;
}

.tabs .tab {
    position: relative;
    flex: 1;
    text-align: center;
    font-weight: bold;
    color: #333333;
}

.tabs .tab.active {
    color: #15c176;
}

.tabs .active::after {
    position: absolute;
    content: '';
    left: calc(50% - 30px);
    bottom: -1px;
    width: 60px;
    height: 4px;
    background: #15c176;
    border-radius: 2px;
}

.privilege {
    margin: 15px;
    padding: 15px;
}

.privilege .title {
    font-size: 17px;
    font-weight: 500;
}

.privilege .ul {
    margin: 10px 0;
}

.privilege .ul .li {
    display: flex;
    padding: 15px 0;
}

.privilege .ul .li .icon {
    width: 35px;
    height: 35px;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 50%;
}

.privilege .ul .li .fr {
    margin-left: 15px;
}

.privilege .ul .li .name {
    font-size: 15px;
    color: #333333;
    font-weight: 500;
}

.privilege .ul .li .desc {
    margin-top: 5px;
    color: #999999;
    font-size: 14px;
}

.rule {
    margin: 15px;
    padding: 0 15px 15px 15px;
    font-size: 15px;
    color: #666666;
}

.rule .ul {
    margin: 5px 0;
}

.rule .li {
    padding: 5px 0;
    font-size: 13px;
    color: #888888;
}

.footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-bottom: 20px;
    width: 100%;
}

.pay-button {
    position: relative;
    bottom: 0px;
    margin: 0 15px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(71, 71, 71, 1);
    border-radius: 25px;
}

.pay-button .tj {
    position: absolute;
    top: 5px;
    left: 25px;
    font-size: 11px;
    color: #FED9A4;
}

.pay-button .fl {
    flex: 1;
    color: #ffffff;
    padding-left: 25px;
    /* padding-top: 7px; */
    font-size: 15px;
}

.pay-button .money {
    margin-right: 2px;
    font-size: 23px;
    font-family: PingFang SC Bold;
    color: rgba(21, 193, 118, 1);
    font-weight: 500;
}

.pay-button .original {
    color: #999999;
    text-decoration: line-through;
    font-size: 14px;
    margin-left: 2px;
}

.pay-button .y {
    color: rgba(21, 193, 118, 1);
    font-size: 14px;
}

.pay-button .fr {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    border-radius: 0px 25px 25px 0;
    background: rgba(21, 193, 118, 1);
    width: 100px;
    text-align: center;
    color: #ffffff;
}
</style>